import React from "react";
import { Panel } from "react-bootstrap";
import { withNamespaces } from "react-i18next";
import { withApi } from "./ApiContext";

class SourceURLs extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Panel bsStyle="primary">
        <Panel.Heading>{t("source_urls.title")}</Panel.Heading>
        <Panel.Body>
          <p>
            <strong>{t("source_urls.achievements")}</strong>
            <br />
            {window.location.origin}/widgets/achievement/
          </p>
          <p>
            <strong>{t("source_urls.credits")}</strong>
            <br />
            {window.location.origin}/widgets/credits/
          </p>
        </Panel.Body>
      </Panel>
    );
  }
}

export default withNamespaces()(withApi(SourceURLs));
